import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "@_actions/auth.actions";
import { useTranslation } from "react-i18next";
import styles from "./style.module.css";
import TextField from "@material-ui/core/TextField";
import Progress from "@_components/Progress/Progress";
import Background from "@_assets/images/women-photographer-wide-bg_001_2000px.png";
import Curve from "@_assets/images/curve_BG_transparent_786px_001.png";

// import {countryListAllIsoData} from "@_utils/helpers";

function LoginPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);
  const customerDetails = useSelector((state) => state.customerReducer);

  // const [country, setCountry] = useState("");
  const [inputs, setInputs] = useState({
    caseNumber: "",

    pin: "",
  });

  //   const getCountry = (countryShort) => {
  //     countryListAllIsoData.map((country) => (
  //       country.code === countryShort && setCountry(country.name)
  //     ))
  //   }

  //   useEffect(() => {
  //     if (customerDetails.customer.country) {
  //       getCountry(customerDetails.customer.country)
  //     }
  // }, [customerDetails.customer.country])

  const handleSubmit = useCallback(
    function handleSubmit(e) {
      if (e) e.preventDefault();
      if (inputs.caseNumber && inputs.pin) {
        // save config cache
        let cache = localStorage.getItem("configCache");
        const searchParams = new URLSearchParams(window.location.search);
        localStorage.clear();
        if (cache !== null) localStorage.setItem("configCache", cache);
        dispatch(
          authActions.login(
            inputs.caseNumber.replace(/\s+/g, ""),
            inputs.pin.replace(/\s+/g, ""),
            window.location.hostname,
            searchParams.get("user_id") || ""
          )
        );
      }
    },
    [dispatch, inputs]
  );

  // Adding a keydown handler event to trigger submit when pressing enter
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSubmit(event);
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [handleSubmit]);

  useEffect(() => {
    if (!localStorage.getItem("user")) {
      dispatch(authActions.logout());
    }
  }, [dispatch]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const caseid = searchParams.get("case_id");
    inputs.caseNumber = caseid;
    if (
      (searchParams.has("user_id") ||
        (searchParams.has("auto_login") && searchParams.get("auto_login"))) &&
      searchParams.has("case_pin")
    ) {
      setInputs({
        caseNumber: searchParams.get("case_id"),
        pin: searchParams.get("case_pin"),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("user_id") || searchParams.has("auto_login")) {
      handleSubmit();
    }
    // eslint-disable-next-line
  }, [inputs]);

  if (auth.loading) {
    return <Progress />;
  }

  return (
    <div className={styles.login_page}>
      <div className={styles.background_container}>
        <img src={Background} alt="Photographer" />
      </div>
      <div>
        <img
          src={Curve}
          alt="Curve"
          className={styles.background_image_curve}
        />
      </div>
      <div className={styles.login_page_content}>
        <div className={styles.login_text_container}>
          <h2 className={styles.login_header_text}>
            {t("_you_received_an_email_from_us")}
          </h2>
          <p className={styles.login_text}>{t("_login_text")}</p>
        </div>
        <div className={styles.login_input_container}>
          {" "}
          <TextField
            value={inputs.caseNumber}
            onChange={(e) => {
              setInputs({ ...inputs, caseNumber: e.target.value });
            }}
            variant="outlined"
            label={t("_case_number")}
            type="text"
            placeholder={t("_case_number")}
            classes={{ root: styles.login_input }}
          />
        </div>
        <div className={styles.login_input_container}>
          <TextField
            value={inputs.pin}
            onChange={(e) => {
              setInputs({ ...inputs, pin: e.target.value });
            }}
            variant="outlined"
            label={t("_pin")}
            type="password"
            placeholder={t("_pin")}
            classes={{
              root: styles.login_input,
            }}
          />
        </div>
        <div>
          {auth.message && (
            <div className={styles.login_error}>
              {auth.message === "Access forbidden: case is being revised." ? (
                <>{t("_case_reviewed_text")}</>
              ) : (
                <>{t("_case_number_or_password_is_incorrect")}</>
              )}
            </div>
          )}
        </div>
        <div className={styles.login_btn_container}>
          {inputs.caseNumber && inputs.pin ? (
            <button
              className={styles.login_btn}
              onClick={handleSubmit}
              disabled={auth.login}
              type="submit"
            >
              {t("_show_case_details")}
            </button>
          ) : (
            <button className={styles.login_btn_disabled}>
              {t("_show_case_details")}
            </button>
          )}
        </div>
        <div
          className={styles.login_info_text}
          style={{ whiteSpace: "pre-line" }}
        >
          {customerDetails.customer.login_text && (
            <p>{customerDetails.customer.login_text}</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
