import { customerConstants } from "@_constants/customer.constants";
import { customerService } from "@_services/customer.service";
import { changeLanguage } from "@_utils/helpers.js";

export const customerActions = {
  customer,
};

function customer(host, language, caseId, userId) {
  return (dispatch) => {
    dispatch(request());
    language = language || localStorage.getItem("i18nextLng");
    language = language && language.split("-")[0];
    caseId = caseId || localStorage.getItem("case_id");

    customerService.customer(host, language, caseId, userId).then(
      (data) => {
        data = {
          caseId: caseId,
          ...data,
        };
        changeLanguage(data.translation);
        dispatch(success(data));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error));
      }
    );
  };
}

function request() {
  return { type: customerConstants.CUSTOMER_REQUEST };
}
function success(data) {
  return { type: customerConstants.CUSTOMER_SUCCESS, data };
}
function failure(error) {
  return { type: customerConstants.CUSTOMER_FAILURE, error };
}
