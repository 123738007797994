import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { customerActions } from "@_actions/customer.actions";
import styles from "./BackBtn.module.css";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { NavLink } from "react-router-dom";

function BackBtn() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const auth = useSelector((state) => state.authReducer);

  const handleGetConfig = () => {
    dispatch(
      customerActions.customer(
        window.location.hostname,
        null,
        null,
        auth.userId
      )
    );
  };

  return (
    <div
      className={styles.back_btn_container}
      onClick={() => handleGetConfig()}
    >
      <NavLink to="caseinfo">
        <div className={styles.text_icon_container}>
          <KeyboardBackspaceIcon />
          <p className={styles.back_btn_text}>{t("_back")}</p>
        </div>
      </NavLink>
    </div>
  );
}

export default BackBtn;
